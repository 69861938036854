import Image from 'next/image';
import Link from 'next/link';
import { useState, useEffect, useRef, useContext } from 'react';
import { getAttributes as fetchAttributes, fetchCarts, getCategories as fetchCategories, fetchSuggestSearch } from '@/lib/Definitions';
import DesktopMenuItem from './DesktopMenuItem';
import MobileMenuItem from './MobileMenuItem';
import { useCartsStore } from '@/stores/useCartsStore';
import Cart from '../Cart';
import { useUserStore } from '@/stores/useUserStore';
import { User } from '@/stores/initialState';
import Search from './Search';
import TopBanner from './TopBanner';
import { useCategoriesStore } from '@/stores/useCategoriesStore';
import { useAttributesStore } from '@/stores/useAttributesStore';
import { useRouter } from 'next/router';
import { TopTextItemProps } from '@/lib/constants';
import TopText from './TopText';
import { GlobalContext, GlobalState } from '@/context/useGlobalContext';
import { useSuggestSearchStore } from '@/stores/useSuggestSearchStore';
import CryptoJS from 'crypto-js';
import axiosInstance from '@/lib/axiosInstance';
import Cookies from 'js-cookie';
import Popup from '../Utils/Popup';


interface HeaderProps {
  top_text_list?: Array<TopTextItemProps>;
}

const Header = (props: HeaderProps) => {
  const router = useRouter();
  const { showCart, setShowCart } = useContext(GlobalContext) as GlobalState;
  const [isSyncing, setSyncing] = useState(false);
  const [isSyncingCategory, setSyncingCategory] = useState(false);
  const [isSyncingAttribute, setSyncingAttribute] = useState(false);
  const [isSyncingSuggestSearch, setSyncingSuggestSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const { carts, getCarts, updateCarts } = useCartsStore();
  const { userInfo, updateUser, getUser } = useUserStore();
  const { updateCategories, getCategories } = useCategoriesStore();
  const { updateAttributes, getAttributes } = useAttributesStore();
  const { updateSuggestSearch, getSuggestSearch } = useSuggestSearchStore();
  const isAuthGuestRef = useRef(false);
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());
  const [categoriesData, setCategoriesData] = useState(getCategories());
  const [attributesData, setAttributesData] = useState(getAttributes());
  const [suggestSearchData, setSuggestSearchData] = useState(getSuggestSearch());

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if ((categoriesData.data.length === 0 || categoriesData.update_date === null || categoriesData.update_date < Date.now() - 600000  ||
      attributesData.data.length === 0 || attributesData.update_date === null || attributesData.update_date < Date.now() - 600000) && !isSyncing) {
      setSyncing(!isSyncing);
      setSyncingCategory(true);
      setSyncingAttribute(true);

      fetchCategories()
        .then((resp) => {
          categoriesData.update_date = Date.now();
          categoriesData.data = resp;
          updateCategories(categoriesData);

          setCategoriesData(categoriesData);

          setSyncingCategory(!isSyncingCategory);
        });

      fetchAttributes()
        .then((resp) => {
          attributesData.update_date = Date.now();
          attributesData.data = resp;
          updateAttributes(attributesData);

          setAttributesData(attributesData);

          setSyncingAttribute(!isSyncingAttribute);
        });
    }
  }, [attributesData, categoriesData, isSyncing, isSyncingAttribute, isSyncingCategory, updateAttributes, updateCategories])

  useEffect(() => {
    if ((suggestSearchData.data.keywords.length === 0 || suggestSearchData.data.products.length === 0 || suggestSearchData.update_date === null || suggestSearchData.update_date < Date.now() - 3600000) && !isSyncingSuggestSearch) {
      setSyncingSuggestSearch(!isSyncingSuggestSearch);

      fetchSuggestSearch()
      .then((resp) => {
        if (resp.keywords !== undefined && resp.products !== undefined && resp.keywords.length !== 0 && resp.products.length !== 0) {
          suggestSearchData.update_date = Date.now();
          suggestSearchData.data = resp;
          updateSuggestSearch(suggestSearchData);

          setSuggestSearchData(suggestSearchData);

          setSyncingSuggestSearch(!isSyncingSuggestSearch);
        }

        setSyncingSuggestSearch(!isSyncingSuggestSearch);
      });
    }
  }, [suggestSearchData, isSyncingSuggestSearch, updateSuggestSearch])

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
      setShowCart(false);
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    };
  }, [router.events, setShowCart]);

  useEffect(() => {
    setUserData(getUser());

    if (userData.membershiplevel !== "none" && userData.membershiplevel !== "" && userData.user_id !== undefined && userData.token !== "" && (cartsData.update_date === null || cartsData.update_date < Date.now() - 600000)) {
      fetchCarts("Bearer " + userData.token, Cookies.get(window.btoa("affiliate")) !== undefined && Cookies.get(window.btoa("affiliate")) !== null ? window.btoa(`affiliate,${Cookies.get(window.btoa("affiliate"))}`) : "")
        .then((response) => {
          if (response !== null) {
            response.forEach((cart) => {
              const cc = cartsData.data.find((c) => c.id === cart.id);
              cart.checked = cc?.checked || false;
            });
            cartsData.data = response;
            cartsData.update_date = Date.now();
            updateCarts(cartsData);
            setCartsData(cartsData);
          }
        })
    }
  }, [cartsData, getUser, updateCarts, userData.membershiplevel, userData.token, userData.user_id, userInfo])

  useEffect(() => {
    setCartsData(getCarts());
  }, [getCarts, carts])

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.removeProperty('overflow'));
  }, [isOpen])

  const show_cart_info = () => {
    setShowCart(true)
  }

  useEffect(() => {
    const authGuest = async () => {
      const body = {
        "username": userData.uuid,
        "logintype": "guest",
        "action": "register",
        "timestamp": new Date().getTime(),
      }

      try {
        const response = await axiosInstance.post(
          '/api/auth',
          {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
        )

        const user: User = {
          user_id: response.data.user_id,
          email: response.data.email,
          membershiplevel: response.data.membership_level,
          token: response.data.token,
          refresh_token: response.data.refresh_token,
          barcode: '',
          mooimom_point: 0,
          name: '',
          phone: '',
          referral_code: '',
          uuid: userData.uuid,
          country_code: '',
          birthday: null,
          gender: '',
          mooimom_point_deactivate_date: null,
          order_status: {
            cancel: 0,
            cancel_shipping: 0,
            completed: 0,
            processing: 0,
            reviewed: 0,
            draft: 0,
            paid: 0,
            delivered: 0,
            return: 0
          },
          married: false,
          login_type: "guest",
          update_date: Date.now(),
        }

        updateUser(user);
        setUserData(user);
      } catch (error) {

      } finally {
        isAuthGuestRef.current = false;
      }
    }

    if (!isAuthGuestRef.current && userData.user_id === undefined && userData.uuid !== "" && userData.uuid !== undefined) {
      isAuthGuestRef.current = true;
      authGuest()
    }
  }, [updateUser, userData])

  return (
    <div id="menu" className="sticky z-[50] top-0 bg-white shadow-lg">
      {router.pathname !== "/checkout" && (
        <div className="h-fit min-h-[32px] md:min-h-[24px] bg-mooimom">
          {props.top_text_list !== null && props.top_text_list !== undefined && props.top_text_list.length > 0 ? (
          <TopText top_text_list={props.top_text_list} />
        ) : (
          <TopBanner />
          )}
        </div>
      )}
      <div className="container hidden grid-cols-12 md:grid">
        <div className="flex items-center justify-start col-span-2">
          <Link href="/">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/mooimom-logo.png"}
              alt="MOOIMOM logo"
              quality={100}
              width={600}
              height={95}
              className="w-full max-w-[130px]"
            />
          </Link>
        </div>
        <div className="flex items-center justify-between col-span-8">
          {categoriesData.data.filter((category) => category.type === 'A' || category.type === 'Special' || category.type === 'BRAND').map((category, index) => (
            <DesktopMenuItem
              key={index}
              bundle_category={category.bundle_category}
              id={category.id}
              image_alt={category.image_alt}
              desktop_image_banner={category.desktop_image_banner}
              mobile_image_banner={category.mobile_image_banner}
              product_banner={category.product_banner}
              image_icon={category.image_icon}
              meta={category.meta}
              name={category.name}
              promotions={category.promotions}
              slug={category.slug}
              subcategory={category.subcategory}
              type={category.type}
              link={category.link}
              label_image={category.label_image}
              brands={category.brands}
              featured_brands={category.featured_brands}
            />
          ))}
          <Link href="/mamapedia-homepage">
            <p className="text-xs font-medium text-black hover:text-mooimom font-poppins">
              Mamapedia
            </p>
          </Link>
        </div>
        <div className="flex justify-end col-span-2 py-4">
          <div className="flex items-center">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/search.png"}
              alt="search icon"
              quality={100}
              width={20}
              height={20}
              className="cursor-pointer"
              onClick={() => setShowSearch(true)}
            />
          </div>
          <Link href={userData.login_type === "guest" ? "/login" : "/user"} className="flex items-center ml-6">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/user.png"}
              alt="user icon"
              quality={100}
              width={20}
              height={20}
              className=""
            />
          </Link>
          <div
            className="relative flex items-center ml-6 cursor-pointer"
            onClick={show_cart_info}
          >
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/cart.png"}
              alt="cart icon"
              quality={100}
              width={20}
              height={20}
              className=""
            />
            {cartsData.data.length > 0 && (
              <div className="absolute z-10 w-4 h-4 text-xs text-center text-white rounded-full bg-review -top-1 -right-1">{cartsData.data.length}</div>
            )}
          </div>
        </div>
      </div>
      <div className="container shadow-[0_0_8px_0_rgba(0,0,0,0.15)] md:hidden bg-mooimom">
        <div className="flex items-center max-h-[60px] py-4">
          <div className="flex-none">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/menu-white.png"}
              alt="menu icon"
              quality={100}
              width={24}
              height={24}
              className="min-w-[20px]"
              onClick={toggleMenu}
            />
          </div>
          <div className="flex items-center justify-center ml-5 grow">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo_w.png"}
              alt="MOOIMOM logo"
              quality={100}
              width={500}
              height={80}
              className="max-w-[70%] max-h-[70%]"
              onClick={() => router.push("/")}
            />
          </div>
          <div className="flex-none">
            <div className="flex items-center">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/search-white.png"}
                alt="search icon"
                quality={100}
                width={24}
                height={24}
                className="cursor-pointer"
                onClick={() => setShowSearch(true)}
              />
              <div
                className="relative ml-4"
                onClick={show_cart_info}
              >
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/cart-white.png"}
                  alt="cart icon"
                  quality={100}
                  width={24}
                  height={24}
                  className=""
                />
                {cartsData.data.length > 0 && (
                  <div className="absolute z-10 w-4 h-4 text-xs text-center text-white rounded-full bg-review -top-1 -right-1">{cartsData.data.length}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`fixed inset-0 bg-black opacity-50 ${isOpen ? 'block' : 'hidden'}`} onClick={toggleMenu}></div>
        <div
          className={`categories fixed w-[90%] z-[50] bg-white top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 left-0 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="container">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
              alt="close icon"
              width={16}
              height={16}
              quality={100}
              onClick={toggleMenu}
              className="my-6 cursor-pointer"
            />
          </div>
          <MobileMenuItem categories={categoriesData.data} isMenuOpen={isOpen} userData={userData} router={router} />
          <div className="py-4 z-[55] bottom-0 border-t border-sku left-0 right-0 fixed w-full">
            <Link href={userData.login_type === "guest" ? "/login" : "/user"}>
              <div className="container flex items-center py-2">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/user.png"}
                  alt="user icon"
                  quality={100}
                  width={24}
                  height={24}
                  className=""
                />
                <p className="ml-[10px] text-sm text-black font-poppins font-normal">Profil</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Cart isVisible={showCart} setIsVisible={setShowCart} />
      <Search isVisible={showSearch} setIsVisible={setShowSearch} />
      <Popup token={userData.token} path={router.asPath} router={router} />
    </div>
  )
}

export default Header
